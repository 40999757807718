import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';

const CustomDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default function InfoModal({ open, closeModal }) {

  const handleClose = () => closeModal();

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={480}>
        <DialogTitle>Information</DialogTitle>
        <CustomDialogContent>
          <div>
            Here is an initial draft of the schedule — but it’s not finished!

            Please click and drag these events to move them and change the duration, or click on an empty space to create something new. You may need to resolve any instances of overlap, so that there is never more than one event happening at any given moment.
          </div>
          <div>
            다음은 일정 초안입니다 — 그러나 아직 완성되지 않았습니다!

            이벤트를 클릭하고 드래그하여 이동하거나 기간을 변경하거나, 빈 공간을 클릭하여 새로운 일정을 생성해주세요. 주어진 시간에 한 번에 하나 이상의 이벤트가 발생하지 않도록 중첩되는 경우를 해결해야 할 수도 있습니다.
          </div>
        </CustomDialogContent>
        <DialogActions>
          <Button variant="contained" size="large" onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}