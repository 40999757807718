import { occupations, occupationsLabel } from './occupations';

export const flatteningEventsForChart = (events) => {
  const flattenedEvts = [];
  Object.keys(occupations).forEach(occupation => {
    const value = events
      .filter(event => occupations[occupation].flat().includes(event.name))
      .reduce((acc, val) => acc + val.value, 0);
    if (value) {
      flattenedEvts.push({
        name: occupation,
        value
      });
    }
  });
  return flattenedEvts;
};

export const formatEventsForChart = (events) => {
  const eventsForChart = [];
  let total = 0;
  let eventsMap = new Map();
  for (let i = 0; i < events.length; i++) {
    if (events[i].diff) {
      const value = fromMinutesToPercentage(events[i].diff);
      total += value;
      const title = events[i].chartTitle || events[i].title;
      if (eventsMap.has(title)) {
        eventsMap.set(title, eventsMap.get(title) + value);
      } else {
        eventsMap.set(title, value);
      }
    }
  }

  Object.keys(occupations).forEach(occupation => {
    const subCategories = occupations[occupation].flat();
    subCategories.forEach(subCategory => {
      if (eventsMap.get(subCategory)) {
        eventsForChart.push({ name: subCategory, value: eventsMap.get(subCategory) });
      }
    });
  });

  eventsForChart.push({
    name: occupationsLabel.unknown,
    value: Math.max(100 - total, 0)
  });

  return eventsForChart.filter(e => e.value > 0.01);
};

export const fromMinutesToPercentage = (hours) => hours / (168 * 60) * 100;

export const fromHoursToPercentage = (hours) => hours / 168 * 100;