import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import styled from 'styled-components';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { occupations, occupationsLabel } from '../utils/occupations';
import dayjs from 'dayjs';

const CustomDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const days = [
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EventModal({ open, closeModal, addModalEventToCalendar, editModalEventToCalendar, deleteModalEventToCalendar, eventFromClick }) {

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (eventFromClick.start) {
      let start = dayjs(eventFromClick.startStr);
      let end = dayjs(eventFromClick.endStr);
      setStartTime(start.$d);
      setStartHourTime(start.$H);
      setStartMinuteTime(start.$m);
      setEndTime(end.$d);
      setEndHourTime(end.$H);
      setEndMinuteTime(end.$m);
      setEventType(eventFromClick.title || '');
      setSelectedDays([dayjs(eventFromClick.startStr).day() - 1]);
    }
    if (eventFromClick.id) {
      setEditMode(true);
    }
  }, [eventFromClick]);

  const [startTime, setStartTime] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [startHourTime, setStartHourTime] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [startMinuteTime, setStartMinuteTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [endHourTime, setEndHourTime] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [endMinuteTime, setEndMinuteTime] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [eventType, setEventType] = useState('');

  const handleChangeEventType = (event) => setEventType(event.target.value);

  const handleChangeStartTime = (event) => {
    setStartHourTime(event.$H);
    setStartMinuteTime(event.$m);
    setStartTime(event.$d);
  };

  const handleChangeEndTime = (event) => {
    setEndHourTime(event.$H);
    setEndMinuteTime(event.$m);
    setEndTime(event.$d);
  };

  const handleChangeSelectedDays = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDays(typeof value === 'string' ? value.split(',') : value);
  };

  const handleCreate = () => {
    addModalEventToCalendar({ startTime, endTime, eventType, selectedDays });
    setEditMode(false);
    handleClose();
  };

  const handleUpdate = () => {
    editModalEventToCalendar({ eventFromClick, startTime, endTime, eventType, selectedDays });
    setEditMode(false);
    handleClose();
  };

  const handleDelete = () => {
    deleteModalEventToCalendar(eventFromClick.id);
    handleClose();
  };

  const handleClose = () => {
    setEditMode(false);
    closeModal();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={480}>
        <DialogTitle>New event</DialogTitle>
        <CustomDialogContent >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker defaultValue={dayjs(eventFromClick.startStr)} onChange={handleChangeStartTime} label="Start time" />
            <TimePicker defaultValue={dayjs(eventFromClick.endStr)} onChange={handleChangeEndTime} label="End time" />
          </LocalizationProvider>
          {!editMode && <FormControl>
            <InputLabel id="repetition-label">Event repetition</InputLabel>
            <Select
              labelId="repetition-label"
              id="repetition-checkbox"
              multiple
              value={selectedDays}
              onChange={handleChangeSelectedDays}
              input={<OutlinedInput label="Day" />}
              renderValue={(selected) => {
                if (selected.length > 0) {
                  return selected.map((day) => days[day]).join(', ');
                }
                return selected.map((day) => days[day]);
              }}
              MenuProps={MenuProps}
            >
              {days.map((day, idx) => (
                <MenuItem key={day} value={idx}>
                  <Checkbox checked={selectedDays.indexOf(idx) > -1} />
                  <ListItemText primary={day} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
          <FormControl>
            <InputLabel htmlFor="event-type-select">Event type</InputLabel>
            <Select
              native
              value={eventType}
              id="event-type-select"
              label="Event type"
              onChange={handleChangeEventType}>
              <option aria-label="None" value="" />
              <optgroup label={occupationsLabel.atRest}>
                {occupations[occupationsLabel.atRest].map((occupation) =>
                  <option value={occupation}>{occupation}</option>)}
              </optgroup>
              <optgroup label={occupationsLabel.personalDevelopmentImposed}>
                {occupations[occupationsLabel.personalDevelopmentImposed].map((occupation) =>
                  <option value={occupation}>{occupation}</option>)}
              </optgroup>
              <optgroup label={occupationsLabel.personalDevelopmentElected}>
                {occupations[occupationsLabel.personalDevelopmentElected].map((occupation) =>
                  <option value={occupation}>{occupation}</option>)}
              </optgroup>
              <optgroup label={occupationsLabel.relationships}>
                {occupations[occupationsLabel.relationships].map((occupation) =>
                  <option value={occupation}>{occupation}</option>)}
              </optgroup>
              <optgroup label={occupationsLabel.nonFunctional}>
                {occupations[occupationsLabel.nonFunctional].map((occupation) =>
                  <option value={occupation}>{occupation}</option>)}
              </optgroup>
            </Select>
          </FormControl>
        </CustomDialogContent>
        <DialogActions>
          <Button variant="contained" size="large" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" size="large" disabled={!eventType} onClick={editMode ? handleUpdate : handleCreate}>{editMode ? 'Update' : 'Create'}</Button>
          {editMode && <Button variant="contained" size="large" onClick={handleDelete}>Delete</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}