import React, { useState } from 'react';

import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import styled from 'styled-components';
import { occupationsColor } from '../utils/occupations';

import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';
import InfoModal from './InfoModal';
import { flatteningEventsForChart } from '../utils/chart';

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  margin: 8px;
  text-align: center;
  font-size: 24px;
`;

const College = styled.div`
  height: 32px;
  text-align: center;
  font-size: 16px;
`;

const CustomIconButton = styled(IconButton)`
  margin-left: auto;  
  margin-right: auto;
  width: 16px;
`;

const CustomTooltipContainer = styled.div`
  background-color: white;
  border: 1px solid grey;
`;

const CustomTooltipName = styled.p`
  margin: 8px 16px;
`;

const RADIAN = Math.PI / 180;

export default function Chart({ name, subjectData, college, hasInfo }) {

  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);

  const openEventModal = () => setIsOpenInfoModal(true);

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius + 30) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN) + 5;
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltipContainer>
          <CustomTooltipName>{payload[0].name}</CustomTooltipName>
        </CustomTooltipContainer>
      );
    }
    return null;
  };

  const flattenedSubjectData = flatteningEventsForChart(subjectData);

  return (
    <ChartContainer>
      <InfoModal open={isOpenInfoModal} closeModal={() => setIsOpenInfoModal(false)} />
      <Title>{name}</Title>
      {hasInfo &&
        <CustomIconButton color="primary" size="large" onClick={openEventModal}>
          <InfoIcon />
        </CustomIconButton>
      }
      {college && <College>{college}</College>}
      <ResponsiveContainer width={600} height={600}>
        <PieChart>
          <Legend
            payload={
              flattenedSubjectData.map(
                (item) => ({
                  id: item.name,
                  type: 'square',
                  value: `${item.name}`,
                  color: occupationsColor[item.name]
                })
              )
            }
          />
          <Tooltip content={<CustomTooltip />} />
          <Pie
            data={flattenedSubjectData}
            cx='50%'
            cy='50%'
            outerRadius={160}
            label={renderCustomizedLabel}
          >
            {flattenedSubjectData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={occupationsColor[entry.name]} />
            ))}
          </Pie>
          <Pie
            data={subjectData}
            cx='50%'
            cy='50%'
            innerRadius={180}
            outerRadius={220}
            label={(data) => `${data.value.toFixed(1)}%`}
            dataKey='value'
          >
            {subjectData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={occupationsColor[entry.name]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}
