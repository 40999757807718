
export const getFullName = (survey) => {
  if (survey.length === 0) {
    return '';
  }
  const firstName = survey.filter((answer) => answer.field?.ref === 'firstName')[0]?.text;
  const lastName = survey.filter((answer) => answer.field?.ref === 'lastName')[0]?.text;
  return `${firstName} ${lastName}`;
};

export const getSleepWeekDays = (survey) => survey.filter((answer) => answer.field.ref === 'sleep-weekDay')[0]?.number;

export const getSleepWeekEnd = (survey) => survey.filter((answer) => answer.field.ref === 'sleep-weekEnd')[0]?.number;

export const getMentalHealth = (survey) => survey.filter((answer) => answer.field.ref === 'mentalHealth')[0]?.number;

export const getMediaSocialConsumption = (survey) => survey.filter((answer) => answer.field.ref === 'mediaSocialConsumption')[0]?.number;

export const getSchoolStart = (survey) => {
  const time = survey.filter((answer) => answer.field.ref === 'school-start')[0]?.text;
  return { startHourTime: parseInt(time.split(':')[0]), startMinuteTime: parseInt(time.split(':')[1]) };
};

export const getSchoolEnd = (survey) => {
  const time = survey.filter((answer) => answer.field.ref === 'school-end')[0]?.text;
  return { endHourTime: parseInt(time.split(':')[0]), endMinuteTime: parseInt(time.split(':')[1]) };
};

export const getHomeworkWeekdays = (survey) => survey.filter((answer) => answer.field.ref === 'homework-weekday')[0]?.number;

export const getHomeworkWeekend = (survey) => survey.filter((answer) => answer.field.ref === 'homework-weekend')[0]?.number;

export const getMathematics = (survey) => survey.filter((answer) => answer.field.ref === 'math')[0]?.choices.refs;

export const getEnglishWriting = (survey) => survey.filter((answer) => answer.field.ref === 'englishWriting')[0]?.choices.refs;

export const getScience = (survey) => survey.filter((answer) => answer.field.ref === 'science')[0]?.choices.refs;

export const getHistory = (survey) => survey.filter((answer) => answer.field.ref === 'history')[0]?.choices.refs;

export const getIntellectual = (survey) => survey.filter((answer) => answer.field.ref === 'intellectual')[0]?.choices.refs;

export const getArtAndMusic = (survey) => survey.filter((answer) => answer.field.ref === 'artAndMusic')[0]?.choices.refs;

export const getSportAndPhysical = (survey) => survey.filter((answer) => answer.field.ref === 'sportAndPhysical')[0]?.choices.refs;

export const getSpiritualAndPhilosophical = (survey) => survey.filter((answer) => answer.field.ref === 'spiritualAndPhilosophical')[0]?.choices.refs;