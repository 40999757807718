import React from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

const FullCalendarContainer = styled.div`
  margin: 64px;
`;

export default function WeeklyCalendar({ handleClickOpen, eventsCalendar, editDropEventToCalendar }) {

  dayjs.extend(weekday);

  return (
    <FullCalendarContainer>
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        headerToolbar={false}
        views={{
          customWeekView: {
            type: 'timeGrid',
            visibleRange: {
              start: dayjs().weekday(1).format('YYYY-MM-DD'),
              end: dayjs().weekday(8).format('YYYY-MM-DD')
            }
          }
        }}
        initialView='customWeekView'
        selectable={true}
        editable={true}
        scrollTimeReset={false}
        contentHeight={'auto'}
        events={{ events: [...eventsCalendar] }}
        select={(eventFromClick) => handleClickOpen(eventFromClick)}
        eventClick={(eventFromClick) => handleClickOpen(eventFromClick.event)}
        eventDrop={(eventFromDrop) => editDropEventToCalendar(eventFromDrop.event)}
        eventResize={(eventResize) => editDropEventToCalendar(eventResize.event)}
      />
    </FullCalendarContainer>
  );
}
