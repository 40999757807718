export const occupationsLabel = {
  atRest: 'At Rest',
  sleep: 'Sleep',
  mentalHealth: 'Mental Health',
  personalDevelopmentImposed: 'Personal Development (Imposed)',
  school: 'School',
  homework: 'Homework',
  academiesAndTutoring: 'Academies & Tutoring',
  personalDevelopmentElected: 'Personal Development (Elected)',
  intellectual: 'Intellectual',
  artAndMusic: 'Art & Music',
  sportAndPhysical: 'Sport & Physical',
  spiritualAndPhilosophical: 'Spiritual & Philosophical',
  relationships: 'Relationships',
  family: 'Family',
  friends: 'Friends',
  community: 'Community',
  society: 'Society',
  nonFunctional: 'Non-functional',
  nonOptional: 'Non-optional',
  mediaSocialConsumption: 'Media/Social consumption',
  unknown: 'Unknown',
  mathematics: 'Mathematics',
  englishWriting: 'English/Writing',
  science: 'Science',
  history: 'History',
  lunch: 'Lunch',
  dinner: 'Dinner',
  morningRoutine: 'Morning Routine',
};

export const occupationsColor = {
  [occupationsLabel.atRest]: '#cad1a8',
  [occupationsLabel.sleep]: '#d5dab9',
  [occupationsLabel.mentalHealth]: '#dfe3cb',
  [occupationsLabel.personalDevelopmentImposed]: '#ead69a',
  [occupationsLabel.school]: '#eedeae',
  [occupationsLabel.homework]: '#f2e6c2',
  [occupationsLabel.academiesAndTutoring]: '#f7efd7',
  [occupationsLabel.mathematics]: '#f7efd7',
  [occupationsLabel.englishWriting]: '#f7efd7',
  [occupationsLabel.science]: '#f7efd7',
  [occupationsLabel.history]: '#f7efd7',
  [occupationsLabel.personalDevelopmentElected]: '#eac199',
  [occupationsLabel.intellectual]: '#ecc7a3',
  [occupationsLabel.artAndMusic]: '#f0d4b8',
  [occupationsLabel.sportAndPhysical]: '#f5e0cc',
  [occupationsLabel.spiritualAndPhilosophical]: '#f9ece0',
  [occupationsLabel.relationships]: '    #d39999',
  [occupationsLabel.family]: '#d7a3a3',
  [occupationsLabel.friends]: '#e0b8b8',
  [occupationsLabel.community]: '#e9cccc',
  [occupationsLabel.society]: '#f2e0e0',
  [occupationsLabel.nonFunctional]: '#000000',
  [occupationsLabel.mediaSocialConsumption]: '#333333',
  [occupationsLabel.unknown]: '#cccccc',
  [occupationsLabel.nonOptional]: '#666666',
  [occupationsLabel.lunch]: '#666666',
  [occupationsLabel.dinner]: '#666666',
  [occupationsLabel.morningRoutine]: '#666666'
};

export const occupations = {
  [occupationsLabel.atRest]: [
    [occupationsLabel.sleep],
    [occupationsLabel.mentalHealth]
  ],
  [occupationsLabel.personalDevelopmentImposed]: [
    [occupationsLabel.school],
    [occupationsLabel.homework],
    [occupationsLabel.academiesAndTutoring]
  ],
  [occupationsLabel.personalDevelopmentElected]: [
    [occupationsLabel.intellectual],
    [occupationsLabel.artAndMusic],
    [occupationsLabel.sportAndPhysical],
    [occupationsLabel.spiritualAndPhilosophical]
  ],
  [occupationsLabel.relationships]: [
    [occupationsLabel.family],
    [occupationsLabel.friends],
    [occupationsLabel.community],
    [occupationsLabel.society]
  ],
  [occupationsLabel.nonOptional]: [
    [occupationsLabel.lunch],
    [occupationsLabel.dinner],
    [occupationsLabel.morningRoutine]
  ],
  [occupationsLabel.nonFunctional]: [
    [occupationsLabel.mediaSocialConsumption]
  ],
  [occupationsLabel.unknown]: [
    [occupationsLabel.unknown]
  ],
};

