import { useEffect, useState } from 'react';

import { getSurveyResultsByEmail } from '../services/TypeFormService';
import { getFullName } from '../utils/surveys';
import { checkAndGenerateDinner, generateAcademicsAndTutoring, generateMediaConsumption, generateMentalHealth, generatePersonalDevelopment, generateSchoolDays, generateWeekDaysHomework, generateWeekDaysMorningRoutine, generateWeekDaysSleep, generateWeekEndHomework, generateWeekEndMorningRoutine, generateWeekendSleep } from '../utils/events';

export function useSurveys(email) {

  const [isLoadingSurvey, setIsLoadingSurvey] = useState(true);
  const [survey, setSurvey] = useState([]);
  const [eventsSurvey, setEventsSurvey] = useState([]);
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    async function fetchSurvey() {
      const surveyData = await getSurveyResultsByEmail(email);
      setSurvey(surveyData);
      let offset = [0, 0, 0, 0, 0, 0, 0];
      let dinner = [false, false, false, false, false, false, false];
      let lunch = [false, false];
      const events = [
        ...generateSchoolDays(surveyData),
        ...generateWeekDaysMorningRoutine(surveyData),
        ...generateWeekEndMorningRoutine(surveyData),
        ...generateWeekendSleep(surveyData),
        ...checkAndGenerateDinner(surveyData, offset, dinner, lunch),
        ...generateWeekDaysSleep(surveyData),
        ...checkAndGenerateDinner(surveyData, offset, dinner, lunch),
        ...generateAcademicsAndTutoring(surveyData, offset),
        ...checkAndGenerateDinner(surveyData, offset, dinner, lunch),
        ...generatePersonalDevelopment(surveyData, offset),
        ...checkAndGenerateDinner(surveyData, offset, dinner, lunch),
        ...generateWeekDaysHomework(surveyData, offset),
        ...checkAndGenerateDinner(surveyData, offset, dinner, lunch),
        ...generateWeekEndHomework(surveyData, offset),
        ...checkAndGenerateDinner(surveyData, offset, dinner, lunch),
        ...generateMentalHealth(surveyData, offset),
        ...generateMediaConsumption(surveyData, offset)
      ].filter(evt => evt.title);
      setEventsSurvey(events);
    }
    setIsLoadingSurvey(true);
    fetchSurvey();
    setIsLoadingSurvey(false);
  }, [email]);

  useEffect(() => setFullName(getFullName(survey)), [survey]);

  return { isLoadingSurvey, eventsSurvey, fullName };

}
