import React, { useEffect, useState } from 'react';
import WeeklyCalendar from './WeeklyCalendar';
import Chart from './Chart';
import styled from 'styled-components';
import EventModal from './EventModal';
import { useParams } from 'react-router-dom';
import { useEventsCalendar } from '../hooks/useEventsCalendar';
import { occupationsLabel } from '../utils/occupations';
import { formatEventsForChart, fromHoursToPercentage } from '../utils/chart';
import { useSurveys } from '../hooks/useSurveys';
import { Button, LinearProgress } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ButtonContainer = styled.div`
  display: flex;
  margin: 16px;
  flex-direction: row-reverse;
`;

const ChartsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export default function Results() {

  const { email } = useParams();
  const { eventsSurvey, fullName } = useSurveys(email);
  const { eventsCalendar, addModalEventToCalendar, editModalEventToCalendar, editDropEventToCalendar, deleteModalEventToCalendar } = useEventsCalendar(email, eventsSurvey);

  const [userChartData, setUserChartData] = useState([]);

  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [eventFromClick, setEventFromClick] = useState({});

  const openEventModal = (eventFromClick) => {
    setIsOpenEventModal(true);
    setEventFromClick(eventFromClick);
  };

  const exportPdf = (fullName) => {
    html2canvas(document.querySelector('#results')).then(canvas => {
      let wid;
      let hgt;
      let img = canvas.toDataURL('image/png', wid = canvas.width, hgt = canvas.height);
      let hratio = hgt / wid;
      let doc = new jsPDF('p', 'pt', 'a4');
      let width = doc.internal.pageSize.width;
      let height = width * hratio;
      doc.addImage(img, 'JPEG', 20, 20, width * 0.88, height * 0.88);
      doc.save(`results_${fullName}.pdf`);
    });
  };

  const closeEventModal = () => setIsOpenEventModal(false);

  useEffect(() => {
    setUserChartData(formatEventsForChart([...eventsCalendar]));
  }, [eventsCalendar]);

  const data = [
    { name: occupationsLabel.sleep, value: fromHoursToPercentage(48) },
    { name: occupationsLabel.school, value: fromHoursToPercentage(34) },
    { name: occupationsLabel.homework, value: fromHoursToPercentage(12) },
    { name: occupationsLabel.academiesAndTutoring, value: fromHoursToPercentage(4) },
    { name: occupationsLabel.intellectual, value: fromHoursToPercentage(22) },
    { name: occupationsLabel.sportAndPhysical, value: fromHoursToPercentage(2) },
    { name: occupationsLabel.family, value: fromHoursToPercentage(4) },
    { name: occupationsLabel.friends, value: fromHoursToPercentage(13) },
    { name: occupationsLabel.dinner, value: fromHoursToPercentage(1) },
    { name: occupationsLabel.lunch, value: fromHoursToPercentage(7) },
    { name: occupationsLabel.morningRoutine, value: fromHoursToPercentage(7) },
    { name: occupationsLabel.mediaSocialConsumption, value: fromHoursToPercentage(14) },
  ];

  return (
    <>
      {!eventsSurvey.length && <LinearProgress />}
      {!!eventsSurvey.length &&
        <>
          <ButtonContainer>
            <Button variant="contained" endIcon={<FileDownloadIcon />} onClick={() => exportPdf(fullName)}>Export results</Button>
          </ButtonContainer>
          <div id="results" >
            <ChartsContainer>
              <Chart name={fullName} subjectData={userChartData} hasInfo={true} />
              <Chart name='CMU CS 합격' subjectData={data} college='세계 최고 컴퓨터과' />
            </ChartsContainer>
            <EventModal open={isOpenEventModal} closeModal={closeEventModal} addModalEventToCalendar={addModalEventToCalendar} editModalEventToCalendar={editModalEventToCalendar} deleteModalEventToCalendar={deleteModalEventToCalendar} eventFromClick={eventFromClick} />
            <WeeklyCalendar handleClickOpen={openEventModal} eventsCalendar={eventsCalendar} editDropEventToCalendar={editDropEventToCalendar} />
          </div>
        </>}
    </>
  );
}
