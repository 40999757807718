import { useEffect, useState } from 'react';

import { generateEvent, generateEventDrop, generateEventsFromModal } from '../utils/events';

export function useEventsCalendar(email, eventsSurvey) {

  const [eventsCalendar, setEventsCalendar] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem(`168-hours-week-${email}`)) {
      setEventsCalendar(eventsSurvey);
    }
  }, [eventsSurvey]);

  useEffect(() => {
    if (localStorage.getItem(`168-hours-week-${email}`)) {
      setEventsCalendar(JSON.parse(localStorage.getItem(`168-hours-week-${email}`)));
    }
  }, [email]);

  const addModalEventToCalendar = (event) => {
    const newEventsCalendar = [...eventsCalendar, ...generateEventsFromModal(event)];
    setEventsCalendar(newEventsCalendar);
    localStorage.setItem(`168-hours-week-${email}`, JSON.stringify(newEventsCalendar));
  };

  const editModalEventToCalendar = (event) => {
    const eventsCalendarCp = Object.assign([], eventsCalendar);
    let foundIndex = eventsCalendarCp.findIndex(evt => evt.id === event.eventFromClick.id);
    eventsCalendarCp[foundIndex] = generateEvent(event, event.selectedDays[0]);
    localStorage.setItem(`168-hours-week-${email}`, JSON.stringify(eventsCalendarCp));
    setEventsCalendar(eventsCalendarCp);
  };

  const editDropEventToCalendar = (event) => {
    const eventsCalendarCp = Object.assign([], eventsCalendar);
    let foundIndex = eventsCalendarCp.findIndex(evt => evt.id === event.id);
    eventsCalendarCp[foundIndex] = generateEventDrop(eventsCalendar[foundIndex], event.start, event.end);
    localStorage.setItem(`168-hours-week-${email}`, JSON.stringify(eventsCalendarCp));
    setEventsCalendar(eventsCalendarCp);
  };

  const deleteModalEventToCalendar = (id) => {
    const eventsCalendarCp = Object.assign([], eventsCalendar);
    let foundIndex = eventsCalendarCp.findIndex(evt => evt.id === id);
    eventsCalendarCp.splice(foundIndex, 1);
    localStorage.setItem(`168-hours-week-${email}`, JSON.stringify(eventsCalendarCp));
    setEventsCalendar(eventsCalendarCp);
  };

  return { eventsCalendar, addModalEventToCalendar, editModalEventToCalendar, editDropEventToCalendar, deleteModalEventToCalendar };
}