import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import Home from './components/Home';
import Results from './components/Results';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/results/:email',
    element: <Results />,
  },
]); 