import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { occupationsColor, occupationsLabel } from './occupations';
import { getArtAndMusic, getEnglishWriting, getHistory, getHomeworkWeekdays, getHomeworkWeekend, getIntellectual, getMathematics, getMediaSocialConsumption, getMentalHealth, getSchoolEnd, getSchoolStart, getScience, getSleepWeekDays, getSleepWeekEnd, getSpiritualAndPhilosophical, getSportAndPhysical } from './surveys';

const allDays = [1, 2, 3, 4, 5, 6, 7];
const weekDays = [1, 2, 3, 4, 5];
const schoolNights = [1, 2, 3, 4, 5];
const weekEndNights = [5, 6];
const weekEnd = [6, 7];

const mappingDays = {
  'monday': 1,
  'tuesday': 2,
  'wednesday': 3,
  'thursday': 4,
  'friday': 5,
  'saturday': 6,
  'sunday': 7,
};

export const generateEventsFromModal = (event) => {
  const generatedDays = event.selectedDays.filter(day => day >= 0).map((day) => generateEvent(event, day));
  return generatedDays;
};

export const generateEvent = (event, day) => {
  dayjs.extend(weekday);
  const newDay = dayjs(event.startTime);
  const start = dayjs(event.startTime).add(day - newDay.day() + 1, 'day');
  const end = dayjs(event.endTime).add(day - newDay.day() + 1, 'day');
  return {
    id: guidGenerator(),
    title: event.title || event.eventType,
    start: start.toDate(),
    end: end.toDate(),
    selectedDays: event.selectedDays,
    diff: Math.abs(end.diff(start, 'minutes')),
    backgroundColor: occupationsColor[event.title || event.eventType],
    borderColor: occupationsColor[event.title || event.eventType]
  };
};

export const generateEventDrop = (event, startDate, endDate) => {
  dayjs.extend(weekday);
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  return {
    start: startDate,
    end: endDate,
    selectedDays: event.selectedDays,
    diff: end.diff(start, 'minutes'),
    ...generateTitleAndColorAttributes(event.title)
  };
};

export const generateSchoolDays = (survey) => {
  const { startHourTime, startMinuteTime } = getSchoolStart(survey);
  const { endHourTime, endMinuteTime } = getSchoolEnd(survey);
  dayjs.extend(weekday);
  return weekDays.map((val) => {
    const start = dayjs().weekday(val).hour(startHourTime).minute(startMinuteTime);
    const end = dayjs().weekday(val).hour(endHourTime).minute(endMinuteTime);
    return {
      start: start.toDate(),
      end: end.toDate(),
      selectedDays: [val + 1],
      diff: Math.abs(end.diff(start, 'minutes')),
      ...generateTitleAndColorAttributes(occupationsLabel.school)
    };
  });
};

export const generateWeekDaysMorningRoutine = (survey) => {
  const { startHourTime, startMinuteTime } = getSchoolStart(survey);
  dayjs.extend(weekday);
  return weekDays.map((val) => {
    const start = dayjs().weekday(val).hour(startHourTime - 1).minute(startMinuteTime);
    const end = dayjs().weekday(val).hour(startHourTime).minute(startMinuteTime);
    return {
      start: start.toDate(),
      end: end.toDate(),
      selectedDays: [val + 1],
      diff: Math.abs(end.diff(start, 'minutes')),
      ...generateTitleAndColorAttributes(occupationsLabel.morningRoutine)
    };
  });
};

export const generateWeekEndMorningRoutine = (survey) => {
  const startHourTime = getSleepWeekEnd(survey);
  dayjs.extend(weekday);
  return weekEnd.map((val) => {
    const start = dayjs().weekday(val).hour(startHourTime - 1).minute(0);
    const end = dayjs().weekday(val).hour(startHourTime).minute(0);
    return {
      start: start.toDate(),
      end: end.toDate(),
      selectedDays: [val + 1],
      diff: Math.abs(end.diff(start, 'minutes')),
      ...generateTitleAndColorAttributes(occupationsLabel.morningRoutine)
    };
  });
};

export const generateWeekDaysSleep = (survey) => {
  const length = getSleepWeekDays(survey);
  const { startHourTime, startMinuteTime } = getSchoolStart(survey);
  dayjs.extend(weekday);
  return schoolNights.map((val) => {
    const end = dayjs().weekday(val).hour(startHourTime - 1).minute(startMinuteTime);
    const start = end.subtract(length, 'hour');
    return {
      start: start.toDate(),
      end: end.toDate(),
      selectedDays: [val + 1],
      diff: Math.abs(end.diff(start, 'minutes')),
      ...generateTitleAndColorAttributes(occupationsLabel.sleep)
    };
  });
};

export const generateWeekendSleep = (survey) => {
  const endHour = getSleepWeekEnd(survey) - 1;
  dayjs.extend(weekday);
  return weekEndNights.map((val) => {
    const start = dayjs().weekday(val).hour(23).minute(0);
    const end = dayjs().weekday(val + 1).hour(endHour).minute(0);
    return {
      start: start.toDate(),
      end: end.toDate(),
      selectedDays: [val + 1],
      diff: Math.abs(end.diff(start, 'minutes')),
      ...generateTitleAndColorAttributes(occupationsLabel.sleep)
    };
  });
};

export const generateWeekDaysHomework = (survey, offset) => {
  const length = getHomeworkWeekdays(survey);
  const { endHourTime, endMinuteTime } = getSchoolEnd(survey);
  dayjs.extend(weekday);
  return weekDays.map((val) => {
    const start = dayjs().weekday(val).hour(endHourTime + offset[val - 1]).minute(endMinuteTime);
    const end = dayjs().weekday(val).hour(endHourTime + offset[val - 1] + length).minute(endMinuteTime);
    offset[val - 1] = offset[val - 1] + length;
    return {
      start: start.toDate(),
      end: end.toDate(),
      selectedDays: [val + 1],
      diff: Math.abs(end.diff(start, 'minutes')),
      ...generateTitleAndColorAttributes(occupationsLabel.homework)
    };
  });
};

export const generateWeekEndHomework = (survey, offset) => {
  const length = getHomeworkWeekend(survey);
  const { endHourTime, endMinuteTime } = getSchoolEnd(survey);
  dayjs.extend(weekday);
  return weekEnd.map((val) => {
    const start = dayjs().weekday(val).hour(endHourTime + offset[val - 1]).minute(endMinuteTime);
    const end = dayjs().weekday(val).hour(endHourTime + + offset[val - 1] + length).minute(endMinuteTime);
    offset[val - 1] = offset[val - 1] + length;
    return {
      start: start.toDate(),
      end: end.toDate(),
      selectedDays: [val + 1],
      diff: Math.abs(end.diff(start, 'minutes')),
      ...generateTitleAndColorAttributes(occupationsLabel.homework)
    };
  });
};


export const generateAcademicsAndTutoring = (survey, offset) => {
  const { endHourTime, endMinuteTime } = getSchoolEnd(survey);
  const daysMathematics = (getMathematics(survey) || []).map(day => mappingDays[day]);
  const daysEnglishWriting = (getEnglishWriting(survey) || []).map(day => mappingDays[day]);
  const daysScience = (getScience(survey) || []).map(day => mappingDays[day]);
  const daysHistory = (getHistory(survey) || []).map(day => mappingDays[day]);
  dayjs.extend(weekday);
  return [
    ...daysMathematics.map((val) =>
      generateAcademicsOrPersonalDevelopmentEvent(endHourTime, endMinuteTime, offset, val, occupationsLabel.mathematics, occupationsLabel.academiesAndTutoring)),
    ...daysEnglishWriting.map((val) =>
      generateAcademicsOrPersonalDevelopmentEvent(endHourTime, endMinuteTime, offset, val, occupationsLabel.englishWriting, occupationsLabel.academiesAndTutoring)),
    ...daysScience.map((val) =>
      generateAcademicsOrPersonalDevelopmentEvent(endHourTime, endMinuteTime, offset, val, occupationsLabel.daysScience, occupationsLabel.academiesAndTutoring)),
    ...daysHistory.map((val) =>
      generateAcademicsOrPersonalDevelopmentEvent(endHourTime, endMinuteTime, offset, val, occupationsLabel.history, occupationsLabel.academiesAndTutoring))
  ];
};

export const generatePersonalDevelopment = (survey, offset) => {
  const { endHourTime, endMinuteTime } = getSchoolEnd(survey);
  const daysIntellectual = (getIntellectual(survey) || []).map(day => mappingDays[day]);
  const daysArtAndMusic = (getArtAndMusic(survey) || []).map(day => mappingDays[day]);
  const daysSportAndPhysical = (getSportAndPhysical(survey) || []).map(day => mappingDays[day]);
  const daysSpiritualAndPhilosophical = (getSpiritualAndPhilosophical(survey) || []).map(day => mappingDays[day]);
  dayjs.extend(weekday);
  return [
    ...daysIntellectual.map((val) =>
      generateAcademicsOrPersonalDevelopmentEvent(endHourTime, endMinuteTime, offset, val, occupationsLabel.intellectual)),
    ...daysArtAndMusic.map((val) =>
      generateAcademicsOrPersonalDevelopmentEvent(endHourTime, endMinuteTime, offset, val, occupationsLabel.artAndMusic)),
    ...daysSportAndPhysical.map((val) =>
      generateAcademicsOrPersonalDevelopmentEvent(endHourTime, endMinuteTime, offset, val, occupationsLabel.sportAndPhysical)),
    ...daysSpiritualAndPhilosophical.map((val) =>
      generateAcademicsOrPersonalDevelopmentEvent(endHourTime, endMinuteTime, offset, val, occupationsLabel.spiritualAndPhilosophical))
  ];
};

export const generateMentalHealth = (survey, offset) => {
  const length = getMentalHealth(survey);
  const { endHourTime, endMinuteTime } = getSchoolEnd(survey);
  dayjs.extend(weekday);
  return allDays.map((val) => {
    const start = dayjs().weekday(val).hour(endHourTime + offset[val - 1]).minute(endMinuteTime);
    const end = dayjs().weekday(val).hour(endHourTime + offset[val - 1] + length).minute(endMinuteTime);
    offset[val - 1] = offset[val - 1] + length;
    return {
      start: start.toDate(),
      end: end.toDate(),
      selectedDays: [val + 1],
      diff: Math.abs(end.diff(start, 'minutes')),
      ...generateTitleAndColorAttributes(occupationsLabel.mentalHealth)
    };
  });
};

export const generateMediaConsumption = (survey, offset) => {
  const lengthMinutes = getMediaSocialConsumption(survey) / 7 * 60;
  const { endHourTime, endMinuteTime } = getSchoolEnd(survey);
  dayjs.extend(weekday);
  return allDays.map((val) => {
    const start = dayjs().weekday(val).hour(endHourTime + offset[val - 1]).minute(endMinuteTime);
    const end = start.add(lengthMinutes, 'minutes');
    offset[val - 1] = offset[val - 1] + length;
    return {
      start: start.toDate(),
      end: end.toDate(),
      selectedDays: [val + 1],
      diff: Math.abs(end.diff(start, 'minutes')),
      ...generateTitleAndColorAttributes(occupationsLabel.mediaSocialConsumption)
    };
  });
};

export const checkAndGenerateDinner = (survey, offset, dinner, lunch) => {
  const { endHourTime, endMinuteTime } = getSchoolEnd(survey);
  dayjs.extend(weekday);
  return [
    ...weekEnd.map((val, idx) => {
      if (offset[val - 1] + endHourTime >= 12 && !lunch[idx]) {
        const start = dayjs().weekday(val).hour(endHourTime + offset[val - 1]).minute(endMinuteTime);
        const end = dayjs().weekday(val).hour(endHourTime + offset[val - 1] + 1).minute(endMinuteTime);
        offset[val - 1] = offset[val - 1] + 1;
        lunch[idx] = true;
        return {
          start: start.toDate(),
          end: end.toDate(),
          selectedDays: [val + 1],
          diff: Math.abs(end.diff(start, 'minutes')),
          ...generateTitleAndColorAttributes(occupationsLabel.lunch)
        };
      }
      return {};
    }),
    ...allDays.map((val, idx) => {
      if (offset[val - 1] + endHourTime >= 18 && !dinner[idx]) {
        const start = dayjs().weekday(val).hour(endHourTime + offset[val - 1]).minute(endMinuteTime);
        const end = dayjs().weekday(val).hour(endHourTime + offset[val - 1] + 1).minute(endMinuteTime);
        offset[val - 1] = offset[val - 1] + 1;
        dinner[idx] = true;
        return {
          start: start.toDate(),
          end: end.toDate(),
          selectedDays: [val + 1],
          diff: Math.abs(end.diff(start, 'minutes')),
          ...generateTitleAndColorAttributes(occupationsLabel.dinner)
        };
      }
      return {};
    })];
};

const generateAcademicsOrPersonalDevelopmentEvent = (endHourTime, endMinuteTime, offset, val, label, chartTitle) => {
  dayjs.extend(weekday);
  const start = dayjs().weekday(val).hour(endHourTime + offset[val - 1]).minute(endMinuteTime);
  const end = dayjs().weekday(val).hour(endHourTime + 1 + offset[val - 1]).minute(endMinuteTime);
  offset[val - 1]++;
  return {
    start: start.toDate(),
    end: end.toDate(),
    selectedDays: [val + 1],
    diff: Math.abs(end.diff(start, 'minutes')),
    ...generateTitleAndColorAttributes(label, chartTitle)
  };
};

const generateTitleAndColorAttributes = (label, chartTitle) => {
  return {
    id: guidGenerator(),
    title: label,
    chartTitle: chartTitle,
    backgroundColor: occupationsColor[label],
    borderColor: occupationsColor[label]
  };
};

function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
}

