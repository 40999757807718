import React, { useEffect } from 'react';
import styled from 'styled-components';

const HomeContainer = styled.div`
  background-color: #1976d2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
`;

const Title = styled.div`
	font-size: 60px;
	color: white;
`;

const Description = styled.div`Í
	font-size: 32px;
	color: white;
`;

export default function Home() {

  useEffect(() => {
    const redirectToSurvey = async () => window.location.replace('https://form.typeform.com/to/yvFOqbBe');
    setTimeout(() => redirectToSurvey(), 3000);
  }, []);

  return (
    <HomeContainer>
      <Title>168</Title>
      <Description>Know yourself -Design your future</Description>
      <Description>version 0.1 alpha</Description>
    </HomeContainer>
  );
}
